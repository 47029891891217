
import styled from 'styled-components';

const FieldLabel = styled.div`
    color: #262823;
    font-family: 'Manrope';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 12px;

    &:last-child {
        margin-bottom: 0;
    }

    &:b {
        font-weight: 700;
    }
`;

export default FieldLabel;