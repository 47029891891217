import styled from 'styled-components';
import cross from '../assets/icons/cross.png';
import close from '../assets/icons/cross.svg';
import burger from '../assets/icons/burger.png';
import copy from '../assets/icons/copy.svg';

export const Button = styled.button`
    border-radius: 4px;
    border: none;
    background: #C1FF73;

    color: #262823;
    text-align: center;
    font-family: Stapel;
    font-size: 14px;
    font-style: normal;
    font-weight: inherit;
    line-height: 20px; /* 142.857% */
    text-transform: uppercase;

    width: 274px;
    height: 40px;

    margin-top: ${({ marginTop }) => marginTop || '23px'};
    margin-bottom: ${({ marginBottom }) => marginBottom || '23px'};

    
    @media only screen and (max-width: 420px) {
        width: ${({ mobileWidth }) => mobileWidth || '274px'};
    }

    @media only screen and (max-width: 920px) {
        width: ${({ tabletWidth }) => tabletWidth || '274px'};
    }

    &:disabled {
        background: #8A99A6;
        color: white;
    }
`;

export const CloseButton = styled.button`
    position: absolute;
    top: 6px;
    right: 6px;
    background: url('${cross}');
    background-color: transparent;
    width: 30px;
    height: 30px;
    border: none;

    &:focus {
        outline: none;
    }
`;

export const BurgerButton = styled.button`
    display: block;
    width: 16px;
    height: 12px;
    border: none;
    padding: 0;
    margin: 0;
    background-image: url('${burger}');
    background-size: cover;
    background-color: transparent;
    align-self: center;
    background-repeat: no-repeat;

    &:focus {
        outline: 0;
    }

    @media only screen and (min-width: 421px) {
        display: none;
    }
`;

export const CloseBurgerButton = styled.button`
    display: block;
    width: 16px;
    height: 20px;
    border: none;
    padding: 0;
    margin: 0;
    background-image: url('${close}');
    background-size: cover;
    background-color: transparent;
    background-repeat: no-repeat;
    align-self: center;

    &:focus {
        outline: 0;
    }

    @media only screen and (min-width: 421px) {
        display: none;
    }
`;

export const BetaKeyButton = styled.button`
    font-family: Stapel;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: #262823;
    border: none;
    background: none;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    
    img {
        margin-right: 12px;
    }

    &:focus {
        outline: none;
    }
`;

export const BuyButton = styled.button`
    width: 530px;
    height: 50px;
    border-radius: 4px;
    border: none;
    font-family: Stapel;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    background: #F6F6F8;
    color: #262823;
    text-transform: uppercase;
    margin-top: 18px;
    padding-left: 31px;
    margin-bottom: 18px;

    &:focus {
        outline: none;
    }

    @media only screen and (max-width: 420px) {
        width: ${({ mobileWidth }) => mobileWidth || '335px'};
    }

    @media only screen and (max-width: 920px) {
        width: ${({ tabletWidth }) => tabletWidth || '335px'};
    }
`;

export const CopyButton = styled.button`
    position: relative;
    font-family: Stapel;
    color: #262823;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    background: transparent;
    border: none;
    position: relative;
    text-transform: uppercase;
    padding-left: 23px;
    margin-top: 16px;
    
    &:before {
        position: absolute;
        left: 0;
        bottom: 3px;
        width: 15px;
        height: 16px;
        display: block;
        content: ' ';
        background: url("${copy}");
    }
`;
