import styled from 'styled-components';
import React from 'react';

const AccordionWrapper = styled.div`
    margin: 28px 0;
`;

const AccordionButton = styled.button`
    font-family: Manrope;
    font-size: 20px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
    background: none;
    border: none;
`;

const AccordionPanel = styled.div`
    padding: 16px;
    background-color: white;
    display: none;
    overflow: hidden;
`;

const Accordion = ({ items }) => {
    const handleOpen = (e) => {
        e.preventDefault();
        const panel = e.target.form.querySelector('div');
        if (panel.style.display === "block") {
            panel.style.display = "none";
        } else {
            panel.style.display = "block";
        }
    }

    return (
        <AccordionWrapper>
            {items.map((item) => (
                <form>
                    <AccordionButton onClick={(e) => handleOpen(e)}>{item.title}</AccordionButton>
                    <AccordionPanel>
                        <p>{item.text}</p>
                    </AccordionPanel>
                </form>)
            )}
        </AccordionWrapper>
    )
}

export default Accordion;
