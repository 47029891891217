
import styled from 'styled-components';
import React from 'react';
import right from '../assets/icons/right-arrow.svg';
import LoaderSmall from './LoaderSmall';


const InputWrapper = styled.div`
    position: relative;
`;

const StyledInput = styled.input`
    width: 274px;
    height: 40px;
    border-radius: 4px;
    border: 1px solid #8A99A6;
    background: #FFF;
    box-shadow: 0px 1px 5px 0px #EEEFF4 inset;

    color: #8A99A6;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;

    padding: 12px 13px;

    ${({ outlineColor }) => `outline: 1px solid ${outlineColor};`}

    &:focus {
        outline: 1px solid #c1ff73;
    }

     @media only screen and (max-width: 920px) {
        width: 335px;
    }
`;

const InputButton = styled.button`
    position: absolute;
    display: block;
    width: 40px;
    height: 40px;
    top: 0;
    right: 1px;
    cursor: pointer;

    border: none;
    background-color: transparent;

    &:after {
        content: ' ';
        background-image: url('${right}');
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        width: 20px;
        height: 11px;
        display: block;
    }
`;

const ErrorHint = styled.label`
    color: #FF737E;
    font-family: Manrope;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    margin-bottom: 0;
`;


const Input = ({ id, inputRef, submit, outlineColor, placeholder, onButtonClick, errorHint, isWrong, isLoading }) => {
    return (
        <InputWrapper id={id}>
            <StyledInput
                ref={inputRef}
                onSubmit={(e) => submit(e)}
                outlineColor={outlineColor}
                placeholder={placeholder} />
            {isLoading ?
                <LoaderSmall /> :
                <InputButton
                    onClick={(e) => submit(e)} />
            }
            {isWrong && <ErrorHint>{errorHint}</ErrorHint>}
        </InputWrapper>
    )
}

export const LoginInput = styled.input`
    width: ${({ width }) => width || "274px"};
    height: 40px;
    border-radius: 4px;
    border: 1px solid #8A99A6;
    background: #FFF;
    box-shadow: 0px 1px 5px 0px #EEEFF4 inset;
    box-sizing: border-box;

    margin: ${({ margin }) => margin || '0px'};
    padding: 10px 15px;
    font-family: Manrope;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
`;

export default Input;
