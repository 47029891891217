const yearsNumeral = (value) => {
    const d = (value.toString()).match(/.*(\d{1})/)[1];
    if (((+d % 10 >= 5)) || (+d % 10) === 0) {
        return `за ${value} лет`;
    }

    if ((+d % 10) >= 2) {
        return `за ${value} года`;
    }

    return `за ${value} год`;
};

const monthsNumeral = (value) => {
    const d = (value.toString()).match(/.*(\d{1})/)[1];
    if ((+d % 10) >= 5 || (+d % 10) === 0) {
        return `за ${value} месяцев`;
    }
    if ((+d % 10) >= 2) {
        return `за ${value} месяца`;
    }
    if ((+d % 10) === 1) {
        return `за ${value} месяц`;
    }
};

const getPlanName = (months, lang) => {
    if (lang === 'ru') {
        if (months === 1) {
            return "за месяц";
        } else if (months === 6) {
            return "за полгода";
        } else if (months === 12) {
            return "за год";
        } else if (months > 12) {
            return yearsNumeral(months / 12);
        } else if (months > 1 && months < 12 && months !== 6) {
            return monthsNumeral(months);
        }
    }

    if (lang === 'en' || lang === 'en-GB') {
        if (months === 1) {
            return "monthly";
        } else if (months === 6) {
            return "for half a year";
        } else if (months === 12) {
            return "annual";
        } else if (months > 12) {
            return `for ${months / 12} years`;
        } else if (months > 1 && months < 12 && months !== 6) {
            return `for ${months} months`;
        }
    }
}

export default getPlanName;
