import React, { useEffect, useState, createContext, useContext } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { Route, Switch } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './pages/Home';
import { Login } from './pages/Login';
import { Profile } from './pages/Profile';
import { Test } from './pages/Test';
import { Loader } from './components/Loader';
import { PlanChange } from './pages/PlanChange';
import { Referal } from './pages/Referal';

import './custom.css'

export const Context = createContext(null);
const App = () => {
    const [user, setUser] = useState({});
    const [isUserLoading, setIsUserLoading] = useState(true);
    const [lang, setLang] = useState("");
    const [isXrayAllowed, setIsXrayAllowed] = useState(false);
    const { t, i18n } = useTranslation();
    const [subscriptions, setSubscriptions] = useState([]);
    const [token, setToken] = useState('');
    const [email, setEmail] = useState('');

    const fetchSubscriptions = async () => {
        axios.get(
            '/api/getindex'
        ).then(
            (res) => {
                setToken(res.data?.data?.token);
                localStorage.setItem('token', res.data?.data?.token);
                setEmail(res.data?.data?.user?.email);
                setSubscriptions(res.data.data.subs);
            },
            (err) => {
                console.error(err);
                setToken('');
                setEmail('');
                setSubscriptions([]);
            }
        )
    }

    const fetchUser = () => {
        return axios.get('/api/getprofile')
        .then(
            (res) => {
                setUser(res.data.data);
                setLang(res.data.data.language);
                setIsUserLoading(false);
                setIsXrayAllowed(res.data.data.isXrayAllowed);
            },
            (err) => {
                console.error(err);
                setUser({});
                setLang("");
                setIsUserLoading(false);
            }
        )
    }

    useEffect(() => {
        fetchUser();

        if (!isUserLoading) {
            if (lang === "ru") {
                i18n.changeLanguage("ru");
            }

            if (lang === "en") {
                i18n.changeLanguage("en");
            }

            if (lang === "") {
                const userLang = navigator.language || navigator.userLanguage;
                i18n.changeLanguage(userLang.split('-')[0]);
            }
        }

        fetchSubscriptions();
    }, [isUserLoading, lang]);

    return (
        <Layout isXrayAllowed={isXrayAllowed} centered={!user.userId}>
            {isUserLoading ? <Loader /> :
                <React.Fragment>
                    {user.userId &&
                        <Context.Provider value={{ subscriptions, setSubscriptions}}>
                            <Switch>
                                <Route exact path='/' render={(props) => <Home {...props} email={email} token={token} isXrayAllowed={isXrayAllowed} />} />
                                <Route path='/profile' component={Profile} />
                                <Route path='/change' render={(props) => <PlanChange  {...props} />} />
                                <Route path='/test' render={(props) => <Test  {...props} />} />
                                <Route path='/referal' render={(props) => <Referal  {...props} />} />
                            </Switch>
                        </Context.Provider> ||
                        <React.Fragment>
                            <Switch>
                                <Route exact path='/' component={Login} />
                                <Route path='/profile' component={Login} />
                                <Route path='/change' component={Login} />
                                <Route path='/test' component={Login} />
                                <Route path='/referal' component={Login} />
                            </Switch>
                        </React.Fragment>}
                </React.Fragment>
            }
        </Layout>
    );
}

export default App;
