
import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
    position: absolute;
    top: 6px;
    right: 10px;
    display: block;
    width: ${({ centered }) => centered ? '100%' : '25px'};
    margin: 0 auto;
`;

const Container = styled.div`
    display: ${({ centered }) => centered ? 'block' : 'inline-block'};
    position: relative;
    width: 20px;
    height: 20px;
    margin: ${({ centered }) => centered ? '0 auto' : '0px'};
`;

const Ring = styled.div`
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    margin: 3px;
    border: 3px solid #8A99A6;
    border-radius: 50%;
    animation: spin 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #8A99A6 transparent transparent transparent;

    &:nth-child(1) {
        animation-delay: -0.45s;
    }
    &:nth-child(2) {
        animation-delay: -0.3s;
    }
    &:nth-child(3) {
        animation-delay: -0.15s;
    }
    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;

const LoaderSmall = ({centered}) => {
    return (
        <Wrapper centered={centered}>
            <Container centered={centered}>
                <Ring />
                <Ring />
                <Ring />
                <Ring />
            </Container>
        </Wrapper>
    );
}

export default LoaderSmall;
