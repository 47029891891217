import styled from 'styled-components';

const Link = styled.a`
    display: flex;
    align-items: center;
    color: ${({ color }) => color || '#262823'};
    font-family: Stapel;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 20px; /* 166.667% */
    text-transform: ${({ textTransform }) => textTransform || 'uppercase'};
    margin-right: ${({ marginRight }) => marginRight || '20px'};
    margin-top: ${({ marginTop }) => marginTop || '0px'};
    cursor: pointer;

    ${({ centered }) => centered ? `
        display: flex;
        justify-content: center;
    ` : ''}

    &:not([href]), &:not([href]):hover, &:hover {
        color: ${({ color }) => color || '#262823'};
    }
`;

export default Link;
