import styled from 'styled-components';

export const SmallHintText = styled.p`
    color: #8A99A6;
    font-family: Manrope;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    margin-bottom: 0px;
    margin-top: ${({ marginTop }) => marginTop || '0px'};
`;

export const MediumHintText = styled.p`
    color: #8A99A6;
    font-family: 'Manrope';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    max-width: 476px;
    margin-top: ${({ marginTop }) => marginTop || '0px'};

    a {
        text-decoration: underline;
        font-weight: ${({ linkWeight }) => linkWeight || "700"};
        color: #8A99A6;
    }
`;

export const BigHintText = styled.p`
    color: #8A99A6;
    font-family: 'Manrope';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    max-width: 530px;
    margin-top: ${({ marginTop }) => marginTop || '0px'};

    a {
        text-decoration: underline;
        font-weight: 700;
        color: #8A99A6;
    }
`;

export const Text = styled.p`
    color: #292929;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: ${({ marginBottom }) => marginBottom || '19px'};
    margin-top: ${({ marginTop }) => marginTop || '0px'};
    padding: ${({ padding }) => padding || '0px'};
    
    a {
        color: #292929; 
        text-decoration: underline;
    }
`;
