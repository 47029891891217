import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import settings from '../settings.json';
import { Text } from '../components/Text';
import Title from '../components/Title';
import Accordion from "../components/Accordion";
import { BetaKeyButton } from "../components/Button";
import beta from '../assets/icons/betakey.svg';
import axios from "axios";

const Wrapper = styled.div`
    padding: 37px 97px;
    min-height: 100vh;
    display: flex;
    flex-flow: column;

    @media only screen and (max-width: 420px) {
        padding: 35px 20px;
        margin-top: 64px;
    }
`;

const Step = styled.h3`
    font-family: Manrope;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    margin-top: 40px;
`;

const StepsWrapper = styled.div`
    margin: 0px 0px;
    max-width: 530px;
`;

const items = [{title: "Android", text: "Android Instruction"}, {title: "iOS", text: "iOS Instruction"}, {title: "MacOS", text: "MacOS Instruction"}];

export const Test = ({ token, email }) => {
    const { t, i18n } = useTranslation();

    return (
        <Wrapper>
            <Title>{t('ThanksTest')}</Title>
            <StepsWrapper>
                <Step>{t('Step1')} </Step>
                <Text marginBottom="28px">{t('Step1Description')}</Text>
                <Accordion items={items} />
                <Step>{t('Step2')} </Step>
                <Text marginBottom="17px">{t('Step2Description')} </Text>
                <BetaKeyButton disabled={true}><img src={beta} /> ключ для beta-теста</BetaKeyButton>
                <Step>{t('Step3')} </Step>
                <Text>{t('Step3Description')} </Text>
            </StepsWrapper>
        </Wrapper>
    );
}
