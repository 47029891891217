import styled from 'styled-components';

const Title = styled.h2`
    font-family: Stapel;
    font-size: 26px;
    font-weight: ${({ fontWeight }) => fontWeight || '400'};
    line-height: 30px;
    letter-spacing: 0px;
    text-align: left;
`;

export const SubTitle = styled.h4`
    font-family: Manrope;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    margin-top: 35px;
    margin-bottom: 15px;

    @media only screen and (max-width: 420px) {
        margin-top: 0;
    }
`;

export default Title;
