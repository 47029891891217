import 'bootstrap/dist/css/bootstrap.css';
import './index.css';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { Loader } from './components/Loader';
import './i18n';

window.addEventListener("DOMContentLoaded", function (e) {
    const rootElement = document.getElementById("root");
    ReactDOM.render(
        <BrowserRouter basename={'/'}>
            <Suspense fallback={<Loader centered={true} />}>
                <App />
            </Suspense>
        </BrowserRouter>, rootElement);
});
