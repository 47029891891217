import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
    position: relative;
    display: inline-block;
    width: ${({ width }) => width || '64px'};
    margin: 0 auto;

    ${({ centered }) => centered && `
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    `}
`;

const Container = styled.div`
    display: inline-block;
    position: relative;
    width: ${({ width }) => width || '80px'};
    height: ${({ height }) => height || '80px'};
`;

const Ring = styled.div`
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: ${({ width }) => width || '64px'};
    height: ${({ height }) => height || '64px'};;
    margin: 8px;
    border: ${({ border }) => border || '8px'} solid #8A99A6;
    border-radius: 50%;
    animation: spin 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #8A99A6 transparent transparent transparent;

    &:nth-child(1) {
        animation-delay: -0.45s;
    }
    &:nth-child(2) {
        animation-delay: -0.3s;
    }
    &:nth-child(3) {
        animation-delay: -0.15s;
    }
    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;

export const Loader = (centered) => {
    return (
        <Wrapper centered={centered}>
            <Container>
                <Ring />
                <Ring />
                <Ring />
                <Ring />
            </Container>
        </Wrapper>
    );
}

export const TraficLoader = () => {
    return (
        <Wrapper width="16px">
            <Container width="20px" height="20px">
                <Ring width="16px" height="16px" border="3px"/>
                <Ring width="16px" height="16px" border="3px" />
                <Ring width="16px" height="16px" border="3px" />
                <Ring width="16px" height="16px" border="3px" />
            </Container>
        </Wrapper>
    );
}